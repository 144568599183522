import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import param from "@basicare/common/src/Helpers/param";
import { apiUrl } from "Constants/apiUrl";

/**
 * Base abstraction for making an axios call to our servers
 * Provides some defaults such as headers and API base url.
 *
 * It is recommended to use this class with a 'Service' class that represents a set of calls
 * e.g. PriceService.getQuote - has an internal reference to an API object and prepares a call
 *
 * Calls return axios objects, so they can be used the same as axios.
 */
class API {
  private static base: string = apiUrl;
  private static defaultHeaders: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  private _instance: AxiosInstance;
  private _statefulApi: boolean;

  constructor(URL: string = API.base) {
    this._statefulApi = URL === API.base;
    const config: AxiosRequestConfig = {
      baseURL: URL,
      headers: API.defaultHeaders,
    };
    if (this._statefulApi) {
      config.withCredentials = true;
      config.withXSRFToken = true;
    }
    this._instance = axios.create(config);
  }

  public get = ({
    location,
    params,
  }: {
    location: string;
    params?: Record<string, any>;
  }): Promise<AxiosResponse> => {
    return this._instance.get(location, {
      params,
      paramsSerializer: (params) => param(params),
    });
  };

  public getDownload = ({
    location,
    config,
  }: {
    location: string;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> => {
    return this._instance.get(location, config);
  };

  public post = async ({
    location,
    body,
  }: {
    location: string;
    body: any;
  }): Promise<AxiosResponse> => {
    if (this._statefulApi) {
      await this._instance.get(`sanctum/csrf-cookie`);
    }
    return await this._instance.post(location, body);
  };
}

export default API;
