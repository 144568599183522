import { classname, ExtendedMeta } from "@jauntin/utilities";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react";
import { Form, Field } from "@reactables/react-forms";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import { plans, PlanTypes } from "@basicare/common/src/Constants/planTypes";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { RxAddPaymentMethod } from "../Rx/RxAddPaymentMethod";
import PaymentService from "Services/PaymentService";
import API from "Helpers/API";
import OopsMessage from "Features/Shared/Components/OopsMessage";
import BrainTreeCCFields, {
  FieldValidStates,
} from "Features/Shared/Components/BrainTreeCCFields";
import { useHostedFields } from "Features/Shared/Hooks/useHostedFields";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import MainLayout from "Features/Shared/Layouts/MainLayout";
import PageContentLayout from "Features/Shared/Layouts/PageContentLayout";
import PlanBtn from "Features/Shared/Components/PlanBtn";
import DifferentAddressFields from "Features/Shared/Components/DifferentAddressFields";

const AddPaymentMethod = () => {
  const { id } = useParams() as { id: string };
  const [state, actions] = useReactable(RxAddPaymentMethod, {
    paymentService: new PaymentService(new API()),
    link: id,
    onSubmitSuccess: () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    },
  });

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);

  const { bindHostedFields, tokenizer } = useHostedFields();

  if (!state) return;

  const {
    verifyLinkRequest,
    form: { root },
    submission,
  } = state;

  const {
    planType,
    payment: {
      billing: { differentAddressFields },
    },
  } = root.value;

  const member = verifyLinkRequest.data;

  const subscriptionInterval = planType ? (
    <span className="summary__line-text font-weight-normal text-capitalize">
      {planType}
    </span>
  ) : (
    "--"
  );

  const price = plans.find(({ planType: a }) => a === planType)?.price;

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  const termsLink = `${process.env.REACT_APP_WP_API_LOCATION}terms-conditions/`;

  if (verifyLinkRequest.loading)
    return (
      <>
        <div className="d-flex justify-content-center align-items-center h-100vh w-100">
          <Spinner animation="border" role="status" />
          <span className="ml-3">Loading...</span>
        </div>
      </>
    );

  return (
    <MainLayout>
      <PageContentLayout>
        {(() => {
          if (verifyLinkRequest.error) return <OopsMessage />;

          if (submission.success)
            return (
              <div className="mw--635 mh--300 d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-uppercase mb-4">Thank you!</h1>
                <h5 className="mb-4 text-center">
                  You have successfully maintained your membership.
                </h5>
                <p className="mb-4 text-center">
                  You will be automatically charged after the trial period ends
                  and at each subsequent payment interval. You will receive a
                  renewal reminder before each automatic payment.
                </p>

                <p className="mt-4 text-center">
                  If you have any questions, please contact us
                  <br />
                  at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
                  <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
                </p>
              </div>
            );

          const showActionBtn =
            root.valid || submitTouched || hasControlTouchError(state.form);

          return (
            <div className="payment-method-update">
              <h1 className="mb-4">
                Please select your desired subscription interval and provide us
                with your payment information to maintain your membership
              </h1>
              <Form rxForm={[state.form, actions.form]}>
                <div className="plans">
                  <h2>
                    Select your Enhanced Care Bundle Subscription Interval
                  </h2>
                  {state.form["planType"].errors.required && submitTouched && (
                    <div className="form-error mb-1">Required</div>
                  )}
                  <div className="row align-items-start">
                    {plans.map((plan) => (
                      <Field
                        key={plan.planType}
                        name="planType"
                        component={PlanBtn}
                        value={plan.planType}
                        cols="4"
                        plan={plan}
                      />
                    ))}
                  </div>
                </div>
                <div className="summary summary--fullScreen">
                  <div className="summary_wrapper">
                    <div className="summary__header d-flex align-items-center">
                      <h2 className="mb-0">SUMMARY</h2>
                      <div className="ml-auto d-none d-lg-block">
                        <span className="bold-1 c-primary">
                          Enhanced Care Bundle Subscription Interval:{" "}
                        </span>
                        {subscriptionInterval}
                      </div>
                    </div>
                    <div className="summary__line d-lg-none">
                      <div className="summary__line-title">
                        <div className="pt-4">
                          <span className="bold-1 c-primary">
                            Enhanced Care Bundle Subscription Interval:{" "}
                          </span>
                          <span className="summary__line-text font-weight-normal">
                            {" "}
                            {subscriptionInterval}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="summary__line pt-4 d-none d-lg-block">
                      <div className="summary__line-title c-primary">
                        Primary Member
                      </div>
                    </div>
                    <div className="summary__line d-lg-none">
                      <div className="summary__line-title c-primary">
                        Primary Member
                      </div>
                    </div>
                    <div className="summary__line row pb-4">
                      <div className="col-6 col-xl-auto mw-xl--200">
                        <div className="summary__line-title">First Name</div>
                        <div className="summary__line-text">
                          {member?.firstName}
                        </div>
                      </div>
                      <div className="col-6 col-xl-auto mw-xl--200">
                        <div className="summary__line-title">Last Name</div>
                        <div className="summary__line-text">
                          {member?.lastName}
                        </div>
                      </div>
                      <div className="col mt-3 mt-xl-0 mw-xl--200">
                        <div className="summary__line-title">
                          Membership Number
                        </div>
                        <div className="summary__line-text">
                          {member?.subscriberNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="summary__selected-plan summary__selected-plan--fullScreen align-items-center">
                    <div className="summary__plan-text">
                      {planType && (
                        <>
                          Total{" "}
                          {<span className="text-capitalize">{planType}</span>}{" "}
                          Cost:
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-column align-items-end flex-md-row align-items-md-center ml-auto">
                      <div
                        className={classname(
                          `summary__plan-price`,
                          `${planType || ""}`
                        )}
                      >
                        {price
                          ? currencyFromInt(price).formatDollars()
                          : "$ --"}
                      </div>
                    </div>
                  </div>
                </div>
                <section className="page-content__section">
                  <h2 className="mb-1">Payment information</h2>
                  {planType && (
                    <div className="mb-3">
                      You will be charged on{" "}
                      {planType === PlanTypes.Annual ? "an" : "a"} {planType}{" "}
                      basis
                    </div>
                  )}
                  <BrainTreeCCFields
                    onHostedFieldsLoaded={bindHostedFields}
                    onValidityChange={actions.form.validateCCFields}
                    onBlur={actions.form.markAsCCFieldTouched}
                    fieldValidStates={
                      {
                        number:
                          state.form["payment.billing.ccFieldsValidity.number"],
                        cardholderName:
                          state.form[
                            "payment.billing.ccFieldsValidity.cardholderName"
                          ],
                        expirationDate:
                          state.form[
                            "payment.billing.ccFieldsValidity.expirationDate"
                          ],
                        cvv: state.form["payment.billing.ccFieldsValidity.cvv"],
                      } as FieldValidStates
                    }
                    enableShowErrors={submitTouched}
                  />
                </section>
                <Field
                  name="payment.billing.sameAddressAsInsured"
                  component={StyledCheckboxField}
                  onChange={(e) => {
                    actions.form.selectSameAddressAsInsured(
                      e.currentTarget.checked
                    );
                  }}
                  label="Billing address is the same as the primary member"
                />
                {differentAddressFields && (
                  <DifferentAddressFields
                    groupName="payment.billing.differentAddressFields"
                    selectBillingAddressPlace={
                      actions.form.selectBillingAddressPlace
                    }
                    clearBillingAddress={actions.form.clearBillingAddress}
                    addressValue={differentAddressFields.address.address}
                    showErrors={showErrors}
                  />
                )}
                <Field
                  name="payment.authorizationConsent"
                  component={StyledCheckboxField}
                  label={
                    <>
                      By confirming your subscription, you allow BasiCare Plus
                      LLC to charge you now and for future payments in
                      accordance with their{" "}
                      <a href={termsLink} target="_blank" rel="noreferrer">
                        terms
                      </a>
                      .
                    </>
                  }
                  showErrors={showErrors}
                />
              </Form>

              <div className="mt-4 d-flex justify-content-start">
                <TwoStageButton
                  actionEnabled={showActionBtn}
                  action={() =>
                    actions.submit({
                      tokenizer,
                      formValue: state.form.root.value,
                    })
                  }
                  touchSubmit={touchSubmit}
                  disableAction={!root.valid}
                  spinning={submission.loading}
                />
              </div>
              {state.submission.error && (
                <div className="form-error d-flex w-100 justify-content-center mt-3">
                  There was an error, please try again
                </div>
              )}
            </div>
          );
        })()}
      </PageContentLayout>
    </MainLayout>
  );
};

export default AddPaymentMethod;
