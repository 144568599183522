import { from, of, concat, throwError, Observable } from "rxjs";
import { map, filter, delay, mergeMap, catchError } from "rxjs/operators";
import {
  Reducers,
  RequestActions,
  RequestState,
  RxRequest,
} from "@jauntin/reactables";
import { Reactable, Action } from "@reactables/core";
import {
  ApplyPromoCodeError,
  ApplyPromoCodePayload,
  ApplyPromoCodeResponse,
  PROMO_CODE_REFERRAL_ERROR,
} from "Features/MembershipSignUp/Models/applyPromoCode.model";
import { AxiosError } from "axios";
import PromoCodeService from "Services/PromoCodeService";
import { APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR } from "Features/MembershipSignUp/Models/applyPromoCode.model";

export type ApplyPromoCodeActions = {
  clearPromoCode: () => void;
} & RequestActions<ApplyPromoCodePayload>;

export const RxApplyPromoCode = ({
  initialState,
  referralParams,
  promoCodeService,
  onPromoCodeReferralError,
  sources = [],
}: {
  initialState: RequestState<ApplyPromoCodeResponse>;
  promoCodeService: PromoCodeService;
  onPromoCodeReferralError: () => void;
  referralParams?: string;
  sources?: Observable<Action<unknown>>[];
}): Reactable<RequestState<ApplyPromoCodeResponse>, ApplyPromoCodeActions> =>
  RxRequest<ApplyPromoCodePayload, ApplyPromoCodeResponse>({
    name: "rxApplyPromoCodeRequest",
    initialState,
    sources: [
      of(referralParams).pipe(
        map((referralParams) => {
          const urlParams = new URLSearchParams(referralParams);
          const code = urlParams.get("promo");

          return code;
        }),
        filter((code) => Boolean(code)),
        map((code) => ({ type: "send", payload: { code, isReferral: true } }))
      ),
      ...sources,
    ],
    reducers: {
      clearPromoCode: () => Reducers.loadableInitialState,
      purchaseSuccess: () => Reducers.loadableInitialState,
      referralError: () => Reducers.loadableInitialState,
    },
    effect: (actions$) =>
      actions$.pipe(
        map(({ payload: { code, subscriptionInterval, isReferral } }) => {
          if (isReferral) {
            return from(promoCodeService.getApplyPromoCode({ code })).pipe(
              map(({ data }) => data),
              catchError((err) => {
                return throwError(() => ({
                  message: PROMO_CODE_REFERRAL_ERROR,
                  payload: err,
                }));
              })
            );
          }
          return from(promoCodeService.getApplyPromoCode({ code })).pipe(
            mergeMap(({ data }) => {
              if (
                subscriptionInterval &&
                subscriptionInterval !== data.subscriptionInterval
              ) {
                return throwError(() => ({
                  message: APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR,
                  promoCode: data,
                }));
              }

              return of(data);
            })
          );
        })
      ),
    catchError: (error: AxiosError) => {
      if (error.message === APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR) {
        return of({
          type: "sendFailure",
          payload: error,
        });
      }
      if (error.message === PROMO_CODE_REFERRAL_ERROR) {
        onPromoCodeReferralError && onPromoCodeReferralError();

        return of({
          type: "referralError",
          payload: error,
        });
      }
      let errorMsg =
        "There was an error applying the promo code. Please try again.";

      if (error.response?.status === 422) {
        errorMsg = (error.response?.data as ApplyPromoCodeError)?.message;
      }

      return concat(
        of({
          type: "sendFailure",
          payload: { message: errorMsg },
        }),
        of({ type: "resetState" }).pipe(delay(5000))
      );
    },
  }) as Reactable<RequestState<ApplyPromoCodeResponse>, ApplyPromoCodeActions>;
